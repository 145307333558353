<template>
  <div class="fetaured-wrap mt-4 mb-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb">
            <ul style="color: #a2a2a2">
              <li><a href="#" @click="homePage">Home</a></li>
              <li v-if="isCurrentMemberAvailable && page=='cart'"><a style="color:#a2a2a2" @click="routeToCurrentRoute" >Current Member</a></li>
              <li  v-if="page=='cart'||page=='plans'"><a href="#" @click="planListing">Plans</a></li>
              <li  v-if="page=='cart'"><a href="#" @click="routeToPlanDetails">Plans Details</a></li>
              <li  v-if="page=='cart'"><router-link to="/cart" class="tooltip-hover">Cart</router-link></li>
              <li><a>Recommended Plans</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12" v-if="recommendedPlans.length > 0">
          <h2><span class="brand-color">Recommended Products</span></h2>
        <div class="plansGrid-wrap mt-0">
          <div>
            <div class="row">
              <div
                class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"
                v-for="(plan, index) in recommendedPlans"
                v-bind:key="index"
              >
                <div class="plan-block card-block text-start">
                  <img :src="plan.plan_logo" alt="Critical" class="img-fluid" />
                  <h3>
                    <a
                      href="#"
                      v-on:click="nextPage(plan.plan_id, plan.plan_name)"
                    >
                      {{ plan.plan_name }}</a
                    >
                  </h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a
                    class="more check-btn"
                    v-on:click="nextPage(plan.plan_id, plan.plan_name)"
                    >More Info</a
                  >
                  <span class="recommend-category">{{
                    plan.plan_category
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>No Any Products.</h2>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "RecommendList",
  data: function () {
    return {
      planId:'',
      agent_id: "",
      group_id: "",
      eprocess: "",
      portal_id: "",
      plan_id: "",
      recommendedPlans: [],
      showHideRecommendedProduct: false,
      baseUrl: process.env.VUE_APP_RECOMMENDED_API,
      page: "",
      filters: {
        age: null,
        dob: null,
        state: null,
        gender: null,
      },
      isCurrentMemberAvailable: false,
    };
  },
  mounted() {
    let app = this;
    app.page = app.$route.params.name;
    app.planId = window.localStorage.getItem('planId');
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
    app.eprocess = window.localStorage.getItem("eprocess");
    app.portal_id = window.localStorage.getItem("portal_id");
    if (app.portal_id !== "") {
      app.getRecommendedPlans();
    }
    app.isCurrentMemberAvailable = window.localStorage.getItem('isCurrentMemberAvailable');
  },
  methods: {
    nextPage: function (pid, planName) {
      console.log(pid);
      this.$router.push("plan/" + pid + "/" + planName);
    },
    getPlansDetails: function (ids) {
      let url = `${
        this.baseUrl
      }/recommendation/plan-details?plan_list=${ids.toString()}`;
      axios
        .get(url)
        .then((res) => {
          this.recommendedPlans = res.data.data;
          this.recommendedPlans.forEach((item, index) => {
            let str = item.plan_category;
            this.recommendedPlans[
              index
            ].plan_category = `${str[0].toUpperCase()}${str.slice(1)}`;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    decryptIds: async function () {
      let app = this;
      let ids = {};
      ids["portalId"] = window.localStorage.getItem("portal_id");
      if (window.localStorage.getItem("planId")) {
        ids["planId"] = window.localStorage.getItem("planId");
      }
       if (window.localStorage.getItem("user_id")) {
        ids["userID"] = window.localStorage.getItem("user_id");
      }
      ids["agentId"] = window.localStorage.getItem("agent_id");
      ids["groupId"] = window.localStorage.getItem("group_id");
      let decryptUrl = `${app.baseUrl}/decrypt-detail`;
      await axios
        .post(decryptUrl, ids)
        .then((res) => {
          if (res.data[0].agentId) {
            app.agent_id = res.data[0].agentId;
          }
          if (res.data[0].groupId) {
            app.group_id = res.data[0].groupId;
          }
          if (res.data[0].portalId) {
            app.portal_id = res.data[0].portalId;
          }
          if (res.data[0].planId) {
            app.plan_id = res.data[0].planId;
          }
          if (res.data[0].userID) {
            app.user_id = res.data[0].userID;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFilters: async function () {
      let app = this;
      let tempId = window.localStorage.getItem("tempId");
      if (tempId != null) {
        await axios
          .get(process.env.VUE_APP_API_BASE + "/get-enrollment-temp-info", {
            params: {
              enrollment_id: tempId,
              column: "filter_info",
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.filters.dob = response.data.data.result.dob;
              app.filters.state = response.data.data.result.state;
              app.filters.tier = response.data.data.result.tier;
              app.filters.gender =
                response.data.data.result.gender == 0 ? "male" : "female";
              let from = app.filters.dob.split("/");
              let birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
              let cur = new Date();
              let diff = cur - birthdateTimeStamp;
              let currentAge = Math.floor(diff / 31557600000);
              app.filters.age = currentAge;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getRecommendedPlans: async function () {
      let app = this;
      await app.getFilters();
      await app.decryptIds();
      let url = `${app.baseUrl}/recommendation/popularity`;
      let params = {};
      let planIds;
      params["platformID"] = app.portal_id;
      params["userID"] = app.user_id ? app.user_id : null;
      params["enroll_type"] = window.localStorage.getItem("enroll_type");
      if (app.page == "cart") {
        params["agentID"] = app.agent_id;
         if (app.plan_id) params["planID"] = app.plan_id;
        params["state"] = window.localStorage.getItem("state");
        url = url.replace("popularity", "association");
      } else {
        if (app.filters.state) {
          params["state"] = app.filters.state;
          params["tier"] = app.filters.tier;
          params["age"] = app.filters.age;
          params["gender"] = app.filters.gender;
          url = url.replace("popularity", "filter");
        }
        if (app.eprocess === "rep") {
          params["agentID"] = app.agent_id;
        } else if (app.eprocess === "applicant") {
          params["agentID"] = app.agent_id;
          params["groupID"] = app.group_id;
        } else {
          params["groupID"] = app.group_id;
        }
      }
      await axios
        .get(url, {
          params: { ...params },
        })
        .then((res) => {
          if (app.page == "cart") {
            planIds = res.data.recs;
          } else {
            planIds = res.data[0].recs;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      let allPlans = [];
      if (Array.isArray(planIds)) {
        allPlans = planIds;
      } else {
        for (let [key, value] of Object.entries(planIds)) {
          console.log(`${key}`);
          allPlans.push(...value);
        }
      }
      app.getPlansDetails(allPlans);
    },
    toggleRecommendedProduct() {
      let app = this;
      if (app.showHideRecommendedProduct) {
        app.showHideRecommendedProduct = false;
      } else {
        app.showHideRecommendedProduct = true;
      }
    },
    homePage: function () {
      if (this.open_type !== null && typeof this.open_type !== "undefined") {
        if (this.referral !== null && typeof this.referral !== "undefined") {
          this.$router.push("/referrals?rid=" + this.referral);
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.eprocess === "rep") {
          this.$router.push("/agent-home");
        } else if (this.eprocess === "applicant") {
          this.$router.push("/member-home");
        } else if (this.eprocess === "group") {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push("/");
          } else {
            this.$router.push("/group-home");
          }
        }
      }
    },
    routeToCurrentRoute () {
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-select-member');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-select-member');
        }
      }
    },
    planListing: function () {
      console.log("retun to plan listing");
      localStorage.removeItem('menuSelectedItem')
      localStorage.removeItem('selectedCategorykey');
      localStorage.removeItem('selectedCategoryValue');
      this.$router.push("/plans");
    },
    routeToPlanDetails () {
      this.$router.push(`/plan/${this.planId}/${this.planName}`)
    },
  },
};
</script>

<style>
.recommend-category {
  color: #11809c;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 15px;
  border-radius: 30px;
  position: absolute;
  top: 5px;
  right: 0px;
}
</style>
