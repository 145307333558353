<template>
  <section class="page-wrap error-page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xxl-6 col-lg-6">
            <div class="text-center">
              <h1>OOPS!</h1>
              <p>Page not found</p>
              <img src="../assets/images/404.svg" class="img-fluid" alt="404 Error">
              <div class="rollback-btn">
                <button @click="routeToHome"
                        class="backHome">
                  Back to Homepage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default  {
  name: 'error',
  methods: {
    routeToHome () {
      let app = this
      app.routeToAccessPage()
    },
    routeToAccessPage() {
      let app = this;
      localStorage.clear();
      app.$router.push("/");
      app.$router.go();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
      app.$store.dispatch("zipCode", null);
      app.$store.commit('MUTATION_SET_ERROR', false); 
    },
  }
}
</script>
